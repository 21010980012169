import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import Player, { TimeEvent } from '@vimeo/player';
import { SafeUrlPipe } from '../../../../pipes/src/safe-url.pipe';
import { Logger } from '@compass/logging';

export interface VideoStatus {
  isReady: boolean;
  loaded: boolean;
  started: boolean;
  completed: boolean;
  failedToLoad: boolean;
}

@Component({
  selector: 'cp-vimeo',
  standalone: true,
  imports: [CommonModule, SafeUrlPipe],
  template: `
    <div class="vimeo-16x9-container" #vimeoContainer>
      <iframe
        class="vimeo-responsive-iframe"
        [src]="src | safeUrl: 'resourceUrl'"
        #player></iframe>
    </div>
  `,
  styleUrl: './vimeo-player.component.scss',
})
export class VimeoPlayerComponent implements AfterViewInit, OnDestroy {
  @Input() src: string = '';

  @Input() playerDisableBack: boolean = false;

  @Input() playerDisableForward: boolean = false;

  @Input() playerDisablePause: boolean = false;

  @Input() afterCompleteHidePlayer: boolean = false;

  @Input() showCaptions: boolean = true;

  @Output() stateChanged: EventEmitter<VideoStatus> =
    new EventEmitter<VideoStatus>();

  constructor(
    private _renderer: Renderer2,
    private _logger: Logger,
  ) {}

  @ViewChild('player', { read: ElementRef }) playerRef!: ElementRef;
  @ViewChild('vimeoContainer', { read: ElementRef })
  vimeoContainerRef!: ElementRef;

  private _player!: Player;

  // video status data to be emitted
  private _videoStatus: VideoStatus = {
    loaded: false,
    isReady: false,
    started: false,
    completed: false,
    failedToLoad: false,
  };

  // internal video data
  private _video = {
    currentTimeSeconds: 0,
    duration: 0,
    seekingMethodActive: false,
  };

  async ngAfterViewInit(): Promise<void> {
    this._player = new Player(this.playerRef.nativeElement);

    this.ready();
    this.setTextTracks();
    this.configureEvents();
  }

  async ngOnDestroy(): Promise<void> {
    await this.destroyPlayer();
  }

  private setTextTracks(): void {
    if (!this.showCaptions) return;

    // If no text-tracks are found, vimeo throws and breaks the player. Catch the errors to prevent that.
    this._player
      .enableTextTrack('en')
      .then(() => this._logger.debug('Text-tracks loaded'))
      .catch(error => {
        switch (error.name) {
          case 'InvalidTrackLanguageError':
            this.logInvalidTextTrack(
              'The default text-track language is invalid',
            );
            break;
          case 'InvalidTrackError':
            this.logInvalidTextTrack('The default text-track is invalid');
            break;
          default:
            this._logger.error(
              'An unknown error occurred, and the text-track could not be set.',
            );
            break;
        }
      });
  }

  private configureEvents(): void {
    this._player.on('play', this.playVideo.bind(this));
    this._player.on('loaded', this.videoLoaded.bind(this));
    this._player.on('pause', this.pauseVideo.bind(this));
    this._player.on('seeking', this.seeking.bind(this));
    this._player.on('timeupdate', this.updateTime.bind(this));
    this._player.on('seeked', this.seeked.bind(this));
    this._player.on('ended', this.complete.bind(this));
  }

  private ready(): void {
    // ready() can resolve with an error, but returns void, use then/catch here to catch it
    this._player
      .ready()
      .then(
        () => {
          this._videoStatus.isReady = true;
          this.emitChanges();
          this._logger.debug('Vimeo player ready.');
        },
        error => {
          this.videoLoadError();
          this._logger.error(
            'Vimeo request responded with success, but contained an error.',
            error,
          );
        },
      )
      .catch(error => {
        this.videoLoadError();
        this._logger.error(
          'An error occurred while making a request to Vimeo.',
          error,
        );
      });
  }

  private playVideo(): void {
    this._videoStatus.started = true;
    this.emitChanges();
  }

  private async videoLoaded(): Promise<void> {
    this._video.duration = await this._player.getDuration();

    this._videoStatus.loaded = true;
    this.emitChanges();
    this._logger.debug('Vimeo player loaded');
  }

  private async pauseVideo(): Promise<void> {
    if (this.playerDisablePause && !this._videoStatus.completed) {
      await this._player.play();
      this._logger.debug(
        'Pausing is disabled for this video. Vimeo player resumed automatically.',
      );
    }
  }

  private async complete(): Promise<void> {
    const playRange = await this._player.getPlayed();

    if (
      this.playerDisableForward &&
      playRange[0][1] - this._video.currentTimeSeconds > 0
    )
      return;

    this._videoStatus.completed = true;

    if (this.afterCompleteHidePlayer) {
      this._renderer.addClass(this.vimeoContainerRef.nativeElement, 'd-none');
      await this.destroyPlayer();
    }

    this.emitChanges();
    this._logger.debug('Vimeo video completed.');
  }

  private async seeking(timeData: TimeEvent): Promise<void> {
    if (!this._video.seekingMethodActive)
      this._video.seekingMethodActive = true;

    await this.seekVideo(timeData);
  }

  private async updateTime(timeData: TimeEvent): Promise<void> {
    if (
      timeData.seconds - 1 < this._video.currentTimeSeconds &&
      timeData.seconds > this._video.currentTimeSeconds
    ) {
      this._video.currentTimeSeconds = timeData.seconds;
    }

    if (timeData.seconds >= this._video.duration) {
      await this.complete();
    }
  }

  private async seeked(timeData: TimeEvent): Promise<void> {
    if (this._video.seekingMethodActive) return;

    await this.seekVideo(timeData);
  }

  private async seekVideo(timeData: TimeEvent): Promise<void> {
    if (
      this._video.currentTimeSeconds > timeData.seconds &&
      this.playerDisableBack
    ) {
      await this._player.setCurrentTime(this._video.currentTimeSeconds);
      this._logger.debug(
        'Backward seek attempted, setting video to prior time.',
      );
    }

    if (
      this._video.currentTimeSeconds < timeData.seconds &&
      this.playerDisableForward
    ) {
      await this._player.setCurrentTime(this._video.currentTimeSeconds);
      this._logger.debug(
        'Forward seek attempted, setting video to prior time.',
      );
    }
  }

  private emitChanges(): void {
    this.stateChanged.emit(this._videoStatus);
  }

  private async destroyPlayer(): Promise<void> {
    await this._player.destroy();
    this._logger.debug('Destroying Vimeo player.');
  }

  private videoLoadError(): void {
    this._videoStatus.failedToLoad = true;
    this.emitChanges();
  }

  private logInvalidTextTrack(message: string): void {
    this._logger.info(
      `${message}. The text-track may not exist, or is in a bad state.`,
    );
  }
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import {
  ConsoleLoggingProvider,
  logDeviceMessage,
  LoggingModule,
  logInitMessage,
} from '@compass/logging';
import {
  ApplicationEnvironment,
  ApplicationEnvironmentModule,
  DebugModule,
  DeviceInfoService,
} from '@compass/environment';
import { appConfig } from '../environment/environment';
import { appVersion } from '../../app-version';
import { CompassHttpModule } from '@compass/http';
import { AuthenticationService } from './shared/services/security/authentication.service';
import { ElementRegistry } from '@compass/elements';
import { MathComponent, MathJaxHelper } from '@compass/math';
import { ReactiveFormsModule } from '@angular/forms';
import { IdentityComponent } from './shared/components/identity/identity.component';
import { CalculatorCopyButtonComponent } from './shared/components/calculator-copy-button/calculator-copy-button.component';
import { PendingAssessmentsComponent } from './shared/components/pending-assessments/pending-assessments.component';
import { DebugWindowComponent } from './shared/components/debug/debug-window/debug-window.component';
import { EphemeralStorageService } from '@compass/storage';
import { NgIdleModule } from '@ng-idle/core';
import { ReportComponent } from './shared/components/report/report.component';
import { PanZoomComponent } from './shared/components/pan-zoom/pan-zoom.component';
import { ParticipantTermsComponent } from './shared/components/participant-terms/participant-terms.component';
import { StartCode } from './shared/helpers/start-code';
import { filterTruthy } from '@compass/helpers';
import { EphemeralStorageKey } from './shared/constants/ephemeral-storage-key';
import { SourceDataService } from './shared/services/ui/source-data.service';
import { ParticipantVimeoPlayerComponent } from './shared/components/vimeo/participant-vimeo-player.component';

function positionAssessmentToStart(): void {
  // Get start code from query string
  const queryStartCode = StartCode.getFromQueryString();
  const parsedPath = filterTruthy(window.location.pathname.split('/'));

  // If there is no start code in query string
  // OR if the start code matches the first part of URL
  // then do nothing.
  if (!queryStartCode || queryStartCode === parsedPath[0]) return;

  // There was a start code in query string AND
  // it is different/missing from the URL.
  // Redirect to new URL.
  window.location.href = `${window.location.origin}/${queryStartCode}`;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      canceledNavigationResolution: 'computed',
      bindToComponentInputs: true,
    }),
    LoggingModule.forRoot(
      [new ConsoleLoggingProvider(appConfig.appName)],
      appConfig.minLogLevel,
    ),
    ApplicationEnvironmentModule.forRoot({
      environment: appConfig.environment,
      version: appVersion,
      name: appConfig.appName,
    }),
    CompassHttpModule.forRoot(appConfig.baseApiUrl, AuthenticationService),
    ReactiveFormsModule,
    DebugModule.forRoot(DebugWindowComponent),
    NgIdleModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => positionAssessmentToStart,
    },
    {
      provide: SourceDataService,
      useValue: SourceDataService.fromQueryString(location.search),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private readonly _appEnv: ApplicationEnvironment,
    private readonly _deviceInfo: DeviceInfoService,
    private readonly _elementRegistry: ElementRegistry,
    private readonly _ephemeralStorage: EphemeralStorageService,
    private readonly _sourceData: SourceDataService,
  ) {
    this.logStartMessage();
    this.registerCustomElements();
    this.storeSourceData();

    MathJaxHelper.import('3');
  }

  private registerCustomElements(): void {
    this._elementRegistry.register('cp-math', MathComponent);
    this._elementRegistry.register('cp-identity', IdentityComponent);
    this._elementRegistry.register('kbd', CalculatorCopyButtonComponent);
    this._elementRegistry.register(
      'cp-pending-assessments',
      PendingAssessmentsComponent,
    );
    this._elementRegistry.register('cp-report', ReportComponent);
    this._elementRegistry.register('img', PanZoomComponent, 'data-panzoom');
    this._elementRegistry.register(
      'cp-participant-terms',
      ParticipantTermsComponent,
    );
    this._elementRegistry.register('cp-vimeo', ParticipantVimeoPlayerComponent);
  }

  private logStartMessage(): void {
    logInitMessage(this._appEnv);
    logDeviceMessage(this._deviceInfo.browser, this._deviceInfo.device);
  }

  private storeSourceData(): void {
    this._ephemeralStorage.store(
      EphemeralStorageKey.SourceData,
      this._sourceData,
    );
  }
}
